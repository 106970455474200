import "../styles/service.css";
import AllServices from "../services.json";
import {ServiceIcon} from "../components/ServiceIcon";

const bg_style = {
    backgroundColor: "var(--bs-black)"
}

export function Service() {
    return (
        <>
            <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-6 mx-auto p-4" style={ bg_style }>
                    <h4 className="text-center mb-0">Bienvenue sur le <strong>Convertisseur XML</strong></h4>
                    <p className="text-center fs-5">Veuillez choisir le partenaire</p>
                    <div className="row mt-4 d-flex justify-content-center">
                        {AllServices.map((service) => (
                            <div key={service.id} className="col-3 py-2 me-4 service-item">
                                <ServiceIcon service={service} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}