import {Modal} from "react-bootstrap";
import React from "react";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ModalLoader = ({show}) => {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="text-center">
                <FontAwesomeIcon icon={fas.faSync} spin={true} size={"4x"} className="text-white"/>
                <h5 className="mt-3 mb-0">Conversion en cours</h5>
                <small>Veuillez patienter SVP</small>
            </Modal.Body>
        </Modal>
    )
}