import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export const ModalInfo = ({message, show, handleClose, icon}) => {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="text-center">
                { icon && (
                    <FontAwesomeIcon icon={icon} size={"4x"} className="text-white mb-2" />
                )}
                <p>{message}</p>
                <button type="button" className="btn btn-info" onClick={handleClose}>Fermer</button>
            </Modal.Body>
        </Modal>
    )
}