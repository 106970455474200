import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fas} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Link} from "react-router-dom";

export const ModalFinished = ({show, fileUrl, handleClose}) => {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="text-center py-5">
                <FontAwesomeIcon icon={fas.faCircleCheck} size={"4x"} className="text-success"/>
                <h5 className="mb-4">Conversion terminée</h5>
                <div>
                    <Link to={fileUrl} className="btn btn-info me-5" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={fas.faDownload} className="text-white"/>&nbsp;
                        Télécharger le fichier
                    </Link>
                    <button type="button" onClick={handleClose} className="btn btn-secondary">Fermer</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}