import {useParams} from "react-router-dom";
import AllServices from "../services.json";
import {ModalInfo} from "../components/ModalInfo";
import {useState} from "react";
import axios from "axios";
import {ModalLoader} from "../components/ModalLoader";
import {ModalFinished} from "../components/ModalFinished";
import {fas} from "@fortawesome/free-solid-svg-icons";

const bg_style = {
    backgroundColor: "var(--bs-black)"
}

export const ServiceUploader = () => {
    const params = useParams();
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showFinished, setShowFinished] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [icon, setIcon] = useState(null);

    const findService = AllServices.filter((data) => data.id === params.id)[0];
    const fileExtensions = findService.supportedFileExtensions;

    const handleChange = (e) => {
        const fileType = e.currentTarget.files[0].type;

        if (!(fileExtensions.includes(fileType))) {
            setMessage("Ce type de fichier n'est pas pris en charge");
            setIcon(null);
            setShow(true);
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = e.target.elements;
        let response = {};

        const data = {
            file: formData.formFile.files,
            type: formData.type.value,
            service: findService.id
        }

        setShowLoader(true);

        try {
            response = await axios.post(process.env.REACT_APP_BACKSERVER, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } catch (error) {
            console.log(error);
        }

        setShowLoader(false);

        if (response.status === 200) {
            setFileUrl(response.data.file);
            setShowFinished(true);
        } else {
            setMessage("Une erreur est survenue, veuillez reéssayer plus tard");
            setIcon(fas.faCircleXmark)
            setShow(true)
        }
    }

    const handleClose = () => {
        setShow(false);
        setShowFinished(false);
    }

    return (
        <>
            <ModalFinished show={showFinished} handleClose={handleClose} fileUrl={fileUrl} />
            <ModalLoader show={showLoader} />
            <ModalInfo show={show} handleClose={handleClose} message={message} icon={icon} />
            <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-6 mx-auto p-4" style={bg_style}>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 text-center">
                            <img src={findService.logo} alt="" className="img-fluid" width="20%"/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                                <div className="row">
                                    <div className="col-8 d-flex">
                                        <div className="form-group me-3" style={{width: "100%"}}>
                                            <label htmlFor="formFile">Veuillez charger le fichier</label>
                                            <input
                                                className="form-control mb-1"
                                                type="file"
                                                id="formFile"
                                                name="sourceFile"
                                                accept={fileExtensions.join(", ")}
                                                onChange={handleChange}
                                            />
                                            <span className="text-warning">
                                                PS: {findService.caption}&nbsp;
                                                <img
                                                src={findService.fileImage}
                                                alt=""
                                                className="img-fluid"
                                                style={{height: "15px"}}
                                            />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex flex-column">
                                        <label>Mode de conversion</label>
                                        <select name="type" style={{height: "35px"}} required>
                                            {findService.actions.map((action) => (
                                                <option key={action.code} value={action.code}>{action.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                { isValid ? (
                                    <div className="text-center mt-4">
                                    <hr style={{border: "1px solid #fff"}}/>
                                        <button type="submit" className="btn btn-info">Lancer la conversion</button>
                                    </div>
                                ) : null }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}