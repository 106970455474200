import React from "react"
import '../styles/home.css'

const h1_style = {
    fontWeight: "bold",
    fontSize: "10em",
    lineHeight: "10rem",
    textShadow: "5px 5px black",
    color: "#FAFBFC",
    textAlign: "center",
    zIndex: "1"
}

export const Home = () => {
    return (
        <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12">
                <h1 style={h1_style}>
                    EXCEL TO XML <br/>
                    CONVERTER
                </h1>
            </div>
        </div>
    )
}